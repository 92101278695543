.cartItem {
    max-width: inherit;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    margin: 0em;
  }

.cartItem .table {
    margin: .5em;
    font-size: 1.2em;
}

.cartItem .quantity{
    float: right;
    margin: 0.1em;
}

.cartItem p {
    font-size: .5em;
    margin: 0em;
}

.cartItem .buttonQty {
    margin: .1em;
  }
.cartItem input {
    width: 3em;
    font-size: .5em;
  }

.cartItem td {
    vertical-align: middle;
    margin: .1em;
  }
  .cartItem .amount {
    margin: 1em;
    float: right;
    margin: 0.1em;
  }

.cartItem .description {
    margin: .1em;
    vertical-align: middle;
    align-items: center;
  }

@media (min-width: 300px) {
    .cartItem p   {
        font-size: .7em;
    }
    .cartItem input   {
        font-size: .5em;
    }
    .cartItem .checkout .alert{
        font-size: .5em;
    }
}
@media (min-width: 600px) {
    .cartItem p   {
        font-size: 1em;
    }
    .cartItem input   {
        font-size: 1em;
    }
    .cartItem .checkout .alert{
        font-size: 1em;
    }
}