.navbar {
    /* background: rgba(0,0,0,0.4); */
    width: inherit;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    float: left;
  }

.logo{
    margin: auto;
    height: 7vh;
    float: left;
    image-rendering: auto;
}

.logo-cart{
    margin: auto;
    height: 11vh;
    float: left;
    image-rendering: auto;
}

.home{
    height: inherit;
    float: left;
    display: inline-flex;
}

.cart{
    position: relative;
    text-align: center;
}

.top-left {
    position: absolute;
    top: 8.5vh;
    right: 0vw;
    background-color: black;
    border-radius: 10em;
    font-size: 1em;
    color: white;
    padding: 0 0.5em;
    font-family: 'Courier New', Courier, monospace;
  }

.badge:after{
    content:attr(value);
    font-size:12px;
    color: white;
    background: black;
    border-radius:50%;
    padding: 0 5px;
    position:relative;
    vertical-align:middle;
    left:-5px;
    top:-20px;
    opacity:0.9;
}

