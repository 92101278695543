
@font-face {
  font-family: 'Copystruct';
  src: url('./fonts/CopystructNormal.woff2') format('woff2'),
      url('./fonts/CopystructNormal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Copystruct';
  margin: 0;
  padding: 0;
  background: #eee;
  background:url("../public/img/background_1920.png") top right no-repeat;
  background-attachment:fixed;
  }
