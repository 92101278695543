.product {
    display: inline-grid;
}

.product-carousel img{
    height: 20em;
}

.product-carousel{
    margin: .5em;
}

.product b{
    font-size: 1em;
    margin: 1em;
}

.product p{
    font-size: 1em;
}

.product .text{
    margin: .25em;
}

@media (min-height: 400px) {
    .product-carousel img {
        height: 20em;
    }
    .product b{
        font-size: .75em;
    }
  }

@media (min-height: 600px) {
    .product-carousel img {
        height: 20em;
    }
    .product b{
        font-size: .75em;
    }
  }
  @media (min-height: 800px) {
    .product-carousel img {
        height: 25em;    
    }
    .product b{
        font-size: 1em;
    }
  }

  @media (min-height: 900px) {
    .product-carousel img {
        max-height: 30em;
        height: 30em;    
    }
    .product b{
        font-size: 1em;
    }
    .product p{
        font-size: 1em;
    }
  }
