
.App {
  text-align: center;
}



.App-header {
  float: left;
  width: -webkit-fill-available;
  height: 100vh;
}

.App-background {
  background-image: url("../public/img/rodeo_cover_mobile_2.png");
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 35em;
  width: inherit;
  height: 100%;
  opacity: 1;
}

.fullpage {
  display: grid;
}

@media (min-width: 600px){
  .App-background {
    background-image: url("../public/img/rodeo_cover_original.png");
    background-size: 40em;
  }
}

.product b {
  margin: 0em;
  align-items: center;
  color: black;
}

.image-button {
  background-image: url('../public/img/buy_button_170.png');
  width: 170px; /* Set the width of the button to fit the image */
  height: 59px; /* Set the height of the button to fit the image */
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin: 0.25em;
}

.image-button b{
  color: black;
}

button:active {
  box-shadow: none;
  transform: translate(3px, 3px);
}




