.cart {
    margin: .5em;
    display: inline-grid;
    width: inherit;
    max-width: inherit;
    font-size: .75em;
    max-width: 40em;
    align-self: center;
} 

.cart h1{
    font-size: 1em;
}

.cart Table{
    font-size: .75em;
}

.cart .description {
    margin: 1em;
    float: left;
    margin: 0.1em;
  }

.cart .amount {
    margin: 1em;
    float: right;
    margin: 0.1em;
  }
.cart .total {
    font-size: 1.5em;
}

.summary {
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    display: flex;
    float: left;
}

.cart .checkout .alert{
    margin: .5em;
    font-size: .5em;
    width: inherit;
    
}

.checkout {
    margin-left: 1em;
    margin-right: 1em;
    align-items: center;
    justify-content: center;
}

.payment {
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    display: contents;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 30em;
  }



@media (min-width: 300px) {
    .cart h1   {
        font-size: 1em;
    }
    .cartItem .checkout .alert{
        font-size: .5em;
    }

}  

@media (min-width: 300px) {
    .cart h1   {
        font-size: 1em;
    }
    .cartItem .checkout .alert{
        font-size: .5em;
    }
}
@media (min-width: 600px) {
    .cart h1   {
        font-size: 2em;
    }
    .cartItem .checkout .alert{
        font-size: 1em;
    }

}

@media (min-width: 1000px) {

}
